import React, { memo, useCallback } from "react"
import { Button, Flex, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { Icon } from "@app/components/Icon"

import type { Location } from "@root/types/global"
import { useGlobalContext } from "@app/providers/global"

type Props = {
  location: Location
}

export const SearchFormWidget: React.FC<Props> = memo(({ location }) => {
  const { additionalSearch } = useGlobalContext()
  const { state, dispatch } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()

  const handleClick = useCallback(() => {
    if (location?.pathname?.includes(routes.SEARCH)) {
      const field = document.querySelector(`[name="q"]`)

      if (field) field.select()
    } else {
      dispatch({
        type: "search",
        payload: !state.activeSearch,
      })
    }
  }, [dispatch, location, routes.SEARCH, state])

  return (
    <Flex
      as={Button}
      alignItems="center"
      justifyContent="flex-start"
      onClick={handleClick}
      title={additionalSearch}
      aria-label={additionalSearch}
      textTransform="uppercase"
      transition="all 0.2s ease"
      variant="ghost"
      _hover={{
        textDecoration: "underline",
        textUnderlineOffset: "3px",
      }}
    >
      <Icon display={{ base: "block", md: "none" }} name="search" width={5} />
      <Text display={{ base: "none", md: "block" }} letterSpacing="wider" lineHeight={1} ml={2.5} mb={-1} size="sm">
        {additionalSearch}
      </Text>
    </Flex>
  )
})
