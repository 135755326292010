const merge = require("deepmerge")

const envConfigs = {
  production: require(`./config.production.js`),
  staging: require(`./config.staging.js`),
}

const stage = process.env.GATSBY_STAGE || "production"

const envConfig = envConfigs[stage]

const baseConfig = {
  store: {
    title: "Fine-Day",
    description: "For Good Nights And Slow Mornings",
    logo: "static/icons/brand/logo.svg",
  },
  stores: {
    "fine-day-shop": envConfigs["production"],
    "fine-day-shop-development": envConfigs["staging"],
  },
  theme: {
    background: "#B4B3B4",
    color: "#FFFFFF",
    display: "minimal-ui",
    icon: "static/images/icon.png",
  },
  services: {
    giftcard: {
      brandId: "fineday-au",
    },
    postmark: {
      template: "FDAU-WISHLIST-PRD",
      from: "service@fine-day.com",
    },
    stock: {
      brandId: "FDAU",
    },
    shopify: {
      adminApiVersion: "2024-07",
      storefrontApiVersion: "2024-07",
    },
    sanity: {
      apiVersion: "2022-02-24",
      projectId: "edukwplv",
      token:
        "sktZXr5AiPl8LBNT2c5TPswrnPMCisgQaJLW0SUDyUk6nIUJZv0yYSZD7rkeKygpbBzLPvWSQNq2tf4Al23122i09xmxZXxPG1i2JWrBEgcdLaUJheyFuthNvrkzOE561gucCxjcTISfQWeCFeQyooOSZ1Dr7rNHfkRUqIHoOpOSfnnNSgid",
    },
    reactify: {
      searchConfig: `https://config.search.reactify.app?shop=${envConfig.services.shopify.defaultShopName}.myshopify.com`,
      includeFields: [
        "handle",
        "images",
        "tags",
        "variants",
        "related",
        "product_type",
        "presentment_price_ranges",
        "storefrontId",
        "vendor",
      ],
      excludeFields: ["link"],
      defaultPageSize: 40,
    },
    location: {
      serviceUrl: "https://ipapi.co/json/?key=Di68RWc6asPYru3MCgBD5bAZIMWaQwIFirSSKxUNjtwx9xDVnU",
      countryFieldKey: "country",
      forceRegion: true,
    },
    firebase: {
      defaultWishlist: "DEFAULT",
      wishlistCollection: "wishlist",
    },
    social: {
      facebook: "//www.facebook.com/sharer/sharer.php?u=",
      instagram: "//instagram.com/direct/inbox",
      messenger: "//www.facebook.com/sharer/sharer.php?u=",
    },
    mapbox: {
      endpoint: "https://api.mapbox.com/geocoding/v5/mapbox.places",
      proximityLocation: {
        // Set to Melbourne CBD, 3000, to bias geocode results to this location
        lng: 144.963161,
        lat: -37.814218,
      },
    },
    emarsys: {
      updateContactsAPI: "https://api.emarsys.net/api/v2/contact/?create_if_not_exists=1",
      triggerContactRegistrationAPI: "https://api.emarsys.net/api/v2/form/",
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: { shopify: { published: { eq: true }, deleted: { ne: true } } }) { edges { node { tags, shopify { handle } } } }`,
      template: "templates/product.tsx",
      groupTagPrefix1: "groupStyle:",
      groupTagPrefix2: "styleCode:",
      hidden: "hidden",
      devPageLimit: 0,
    },
    collections: {
      query: `allSanityCollection(filter: { shopify: { published: { eq: true }, deleted: { ne: true } } }) { edges { node { shopify { handle } parent: parentCollection { shopify { handle } parent: parentCollection { shopify { handle } } } } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 0,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 0,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    optimizelyID: "26168410398",
    product: {
      badgePrefix: "flag:",
      beddingTags: ["category:Bedding"],
      colourOptionName: "Colour",
      colourPrefix: "colour:",
      discountAttribute: "_script_discount",
      fabricPrefix: "fabric:",
      filterSortOrder: [
        "0-50",
        "50-100",
        "100-150",
        "150-200",
        "200+",
        "Single",
        "KingSingle",
        "King Single",
        "Double",
        "Queen",
        "King",
        "SuperKing",
        "Super King",
        "XXXS",
        "XXXS",
        "XXXS",
        "XXXS",
        "XXS",
        "XS",
        "Extra Small",
        "XS/S",
        "S",
        "Small",
        "SM",
        "S/M",
        "M",
        "Medium",
        "ML",
        "M/L",
        "L",
        "Large",
        "L/XL",
        "XL",
        "Extra Large",
        "XXL",
        "XXXL",
        "OS",
        "ONESIZE",
        "0000",
        "000",
        "00",
        "0",
      ],
      genderFemale: "female",
      genderMale: "male",
      giftCardSortOrder: [
        "_gift_sender_name",
        "_gift_recipient_name",
        "_gift_recipient_email",
        "_gift_recipient_delivery_date",
        "_gift_recipient_message",
      ],
      giftCardType: "Gift Card",
      personalisationSortOrder: ["text", "font", "size", "colour"],
      priceOptionName: "Price",
      siblingPrefix: "styleCode:",
      siblingSizePrefix: "groupStyle:",
      sizeOptionName: "Size",
      swatchPrefix: "swatch:",
      swatchURL: "//fine-day-swatches.s3.ap-southeast-2.amazonaws.com/",
    },
    cart: {
      gwpAttribute: "_is-gwp",
      gwpAutoAdd: "_auto-add",
      expiryTimeInDays: 2,
    },
    customer: {
      tagPrefix: "preferences",
    },
    keys: {
      market: "market",
      announcement: "announcement",
      checkout: "checkout",
      country: "country",
      customer: "customer",
      collectionColumn: "collectionColumn",
      discounts: "discounts",
      location: "location",
      maintenance: "maintenance",
      newsletter: "subscribe",
      password: "password",
      place: "place",
      position: "position",
      recent: "recent",
      shopify: "shopify",
      wishlist: "wishlist",
      wishlistTip: "wishlistTip",
      collectionScrollPosition: "collectionScrollPosition",
      pageSize: "collectionPageSize",
      recentViewed: "recentViewed",
      authenticating: "authenticating",
      updatingAccount: "updatingAccount",
      productGuideActivePage: "productGuideActivePage",
      cart: "cart",
    },
    params: {
      checkout: "checkout_url",
      continue: "return",
      variant: "variant",
      preview: "preview",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      ERROR: "/404",
      CARE: "/care",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist/shared",
      GIFTCARD: "/giftcard",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      INVALID: "/account/invalid_token",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      DETAILS: "/account/details",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      SET: "/sets",
      ARTICLE: "/articles",
      CATEGORY: "/articles/category",
      STORE: "/stores",
      TRADE: "/trade-enquiries",
      COMPETITION: "/competition",
      CONTACT_SIGNUP: "/sign-up",
    },
    defaultRedirects: [],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/account",
      "/account/activate",
      "/account/addresses",
      "/account/details",
      "/account/forgot",
      "/account/invalid_token",
      "/account/orders",
      "/account/reset",
      "/cart",
      "/giftcard",
      "/password",
      "/search",
      "/styleguide",
      "/wishlist/shared",
    ],
  },
}

module.exports = merge(baseConfig, envConfig)
