import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { HStack, Box, Flex } from "@chakra-ui/react"
import Measure, { MeasuredComponentProps } from "react-measure"

import { useAppContext } from "@app/providers/app"
import { CartWidget } from "@app/components/Cart/CartWidget"
import { HeaderBrand } from "@app/components/Header/HeaderBrand"
import { SearchFormWidget } from "@app/components/Search/Form/SearchFormWidget"
import { HeaderNavigationDesktop } from "@app/components/Header/Navigation/HeaderNavigationDesktop"
import { HeaderNavigationMobile } from "@app/components/Header/Navigation/HeaderNavigationMobile"
import { WishlistWidget } from "@app/components/Wishlist/WishlistWidget"
import { AccountWidget } from "@app/components/Account/Widget/AccountWidget"
import { HeaderNavigationWidget } from "@app/components/Header/Navigation/HeaderNavigationWidget"
import { Announcement } from "@app/components/Announcement"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

export const Header: React.FC<Props> = memo(({ location }) => {
  const [hover, setHover] = useState<boolean>(false)
  const { activeHeaderOffset, calculateOffset, headerRef, state } = useAppContext()
  const [isScrolled, setIsScrolled] = useState(false)
  const [activeDesktop, setActiveDesktop] = useState<string | null>(null)
  const anouncementRef = useRef(null)

  const handleHover = useCallback((state: boolean) => setHover(state), [setHover])

  const handleResize = useCallback(() => calculateOffset(), [calculateOffset])

  const headerPosition = useMemo(() => (state.activeInversion ? "fixed" : "sticky"), [state.activeInversion])

  useEffect(() => {
    const isScrolledFunction = () => {
      if (window.scrollY > 34) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", isScrolledFunction)
    return () => {
      window.removeEventListener("scroll", isScrolledFunction)
    }
  }, [isScrolled])

  return (
    <>
      <Box
        opacity={state.activeNavigation ? 1 : 0}
        pos="fixed"
        inset={0}
        h="full"
        w="full"
        transition="all 0.2s ease"
        visibility={state.activeNavigation ? "visible" : "hidden"}
        zIndex={9}
      />

      <Measure onResize={handleResize}>
        {({ measureRef }: MeasuredComponentProps) => (
          <Box
            as="header"
            ref={headerRef}
            pos={headerPosition}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            bg={
              !state.activeMenu && !state.activeNavigation && !hover && !isScrolled && !activeDesktop
                ? state.activeBackground
                : "grey.white"
            }
            color="coal"
            paddingBottom="1"
            top={isScrolled ? `-${anouncementRef.current?.clientHeight}px` : 0}
            right={0}
            left={0}
            transition="all 0.2s ease"
            zIndex="sticky"
            sx={{
              ".chakra-modal__content-container, .chakra-modal__content, .chakra-modal__overlay": {
                top: `${activeHeaderOffset || 0}px !important`,
              },
            }}
          >
            <Box ref={anouncementRef}>
              <Announcement />
            </Box>
            <Box ref={measureRef}>
              <Box paddingX={{ base: "2", md: "14" }} maxW="container.xl" marginX="auto">
                <Flex gap={3} display={{ base: "none", md: "flex" }}>
                  <Flex w={140} alignItems="center">
                    <HeaderBrand />
                  </Flex>
                  <Flex overflow="hidden">
                    <HeaderNavigationDesktop themeColour="coal" active={activeDesktop} setActive={setActiveDesktop} />
                  </Flex>
                  <Flex alignItems="center" justifyContent="flex-end" gap={6} marginLeft="auto">
                    <SearchFormWidget activeDesktop={activeDesktop} hover={hover} location={location} />
                    <AccountWidget setActive={setActiveDesktop} />
                    <WishlistWidget setActive={setActiveDesktop} />
                    <CartWidget hover={hover} activeDesktop={activeDesktop} />
                  </Flex>
                </Flex>

                <Box display={{ base: "block", md: "none" }}>
                  <HStack alignItems="center" justifyContent="space-between" spacing={4} mb={{ md: 5 }}>
                    <Flex alignItems="center" justifyContent="flex-start" w={1 / 3}>
                      <HeaderNavigationWidget hover={hover} />
                    </Flex>
                    <Box w={194}>
                      <HeaderBrand />
                    </Box>
                    <Flex alignItems="center" justifyContent="flex-end" w={1 / 3}>
                      <SearchFormWidget hover={hover} activeDesktop={activeDesktop} location={location} />
                      <CartWidget hover={hover} activeDesktop={activeDesktop} />
                    </Flex>
                  </HStack>
                </Box>
                <HeaderNavigationMobile />
              </Box>
            </Box>
          </Box>
        )}
      </Measure>
    </>
  )
})
