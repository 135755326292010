import React, { memo, useCallback, useMemo, useState, lazy, Suspense, useEffect } from "react"
import { AspectRatio, Box, GridItem, GridItemProps, Image, Stack, Text } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import "swiper/css"

import { InView } from "react-intersection-observer"

import { useAnalytics } from "@app/hooks/useAnalytics"
import { useMedia } from "@app/hooks/useMedia"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useCart } from "@app/hooks/useCart"
import { useWishlist } from "@app/hooks/useWishlist"
import { useStorage } from "@app/hooks/useStorage"
import { Link } from "@app/components/Link"
import { CollectionLayoutType, ProductCardLanguage } from "@app/components/Collection/Collection"
import { WishlistProduct } from "@app/providers/wishlist"

import type { ElasticImage, ElasticProduct } from "@usereactify/search"
import type { ImageProps, ProductProps } from "@root/types/global"
import { useSwatch, type GroupSize, type SwatchFull } from "@app/hooks/useSwatch"

SwiperCore.use([Pagination])
import { useAppContext } from "@app/providers/app"
import { useImage } from "@app/hooks/useImage"
import { useOutsideClick } from "@app/hooks/useOutsideClick"

const Discount = lazy(() => import("@app/components/Discount"))
const ProductCardSwatches = lazy(() => import("@app/components/Product/Card/ProductCardSwatches"))
const ProductContentBadges = lazy(() => import("@app/components/Product/Content/ProductContentBadges"))
const ProductFormPrice = lazy(() => import("@app/components/Product/Form/ProductFormPrice"))
const ProductAddToCart = lazy(() => import("@app/components/Product/Card/ProductAddToCart"))
const ProductQuickAdd = lazy(() => import("@app/components/Product/Card/ProductQuickAdd"))
const ProductWishlistButton = lazy(() => import("@app/components/Product/Card/ProductWishlistButton"))

export type ProductCardProps = {
  aspectRatio?: number | Array<number>
  isFeatured?: boolean
  item: ProductProps | ElasticProduct
  handleTrackingClick?: () => void
  list?: string
  pagePosition: number
  collectionHandle?: string
  mobileLayout?: CollectionLayoutType
  productCardLanguage?: ProductCardLanguage
  isHomepage?: boolean
  disableSwipe: boolean | undefined
}

export type ProductActiveProps = {
  product: ProductProps
  name: string
}

type Props = ProductCardProps & GridItemProps

type Image = ImageProps | ElasticImage | undefined

export const ProductCard: React.FC<Props> = memo(
  ({
    aspectRatio = 320 / 450,
    item,
    handleTrackingClick = null,
    list,
    pagePosition,
    itemRef,
    collectionHandle,
    mobileLayout,
    productCardLanguage,
    isHomepage,
    disableSwipe,
    ...props
  }) => {
    const {
      settings: {
        keys,
        product: { giftCardType },
      },
    } = useConfigContext()
    const {
      helpers: { isBrowser },
    } = useCore()
    const { storage } = useStorage()
    const { formatActiveProduct } = useSwatch()
    const isReactifyData = "storefrontId" in item
    const [activeProduct, setActiveProduct] = useState<SwatchFull | null>(
      formatActiveProduct(item, null, true, isReactifyData) as SwatchFull
    )
    const storefrontId = isReactifyData ? (activeProduct?.product as unknown as ElasticProduct)?.storefrontId : null

    const { existsInWishlist, addToWishlist, deleteFromWishlist } = useWishlist()
    const { activeGender } = useAppContext()
    const { trackProductClick, trackProductImpression } = useAnalytics()
    const { isTouch, isSmall } = useMedia()
    const { checkImageGender } = useImage()
    const { addToCart, loading } = useCart()
    const [allSwatches, setAllSwatches] = useState([] as Array<SwatchFull>)

    const [productSizes, setProductSizes] = useState([] as Array<GroupSize>)
    const [firstAvailableVariant, setFirstAvailableVariant] = useState(
      activeProduct?.variants?.find(variant => variant.availableForSale == true) || item?.variants?.[0] || undefined
    )
    const [inWishlist, setWishlistState] = useState(false)
    const [quickAddOpen, setQuickAddOpen] = useState(false)
    const [isSensorActive, setIsSensorActive] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    // For product impression tracking
    const [isTracked, setIsTracked] = useState(false)

    const sortImages = useCallback(
      (a: Image, b: Image) => {
        const srcA = a?.src.toLowerCase()
        const srcB = b?.src.toLowerCase()

        const containsSubstringA = checkImageGender(srcA as string, activeGender)
        const containsSubstringB = checkImageGender(srcB as string, activeGender)

        if (containsSubstringA && !containsSubstringB) {
          return -1 // a comes first
        } else if (!containsSubstringA && containsSubstringB) {
          return 1 // b comes first
        } else {
          return 0 // maintain original order
        }
      },
      [activeGender, checkImageGender]
    )

    const images = useMemo(() => activeProduct?.images.sort(sortImages) || [], [activeProduct, sortImages])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const primaryImage = useMemo(() => images?.[0], [images, activeGender])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const secondaryImage = useMemo(() => images?.[1], [images, activeGender])

    const isCollectionPage = !!mobileLayout
    const showAddToCart = mobileLayout === "single" && activeProduct != null && !isSmall && activeProduct.type !== giftCardType

    const handleActiveProduct = useCallback((activeSwatch: SwatchFull) => {
      setActiveProduct(activeSwatch)
      setFirstAvailableVariant(activeSwatch?.variants.find(variant => variant.availableForSale == true) || activeSwatch?.variants?.[0])
    }, [])

    useEffect(() => {
      if (storefrontId) {
        setWishlistState(existsInWishlist(storefrontId))
      }
    }, [existsInWishlist, storefrontId])

    const onMouseEnter = useCallback(() => {
      if (!isTouch && isSmall) setQuickAddOpen(true)
    }, [isSmall, isTouch])

    const onMouseLeave = useCallback(() => {
      if (!isTouch && isSmall) setQuickAddOpen(false)
    }, [isSmall, isTouch])

    const handleAddToCart = useCallback(
      (variantId: string) => {
        variantId ? addToCart(variantId, 1) : !loading
      },
      [addToCart, loading]
    )

    // Toggle quick add or add to card if the product has variants
    const onAddToCartClick = useCallback(() => {
      if (activeProduct && activeProduct.variants.length == 1 && productSizes.length < 2) {
        handleAddToCart(activeProduct.variants[0].id)
      } else {
        setQuickAddOpen(!quickAddOpen)
      }
    }, [activeProduct, handleAddToCart, productSizes.length, quickAddOpen])

    // Open/close quick add on mobile
    const setQuickAdd = useCallback((toggleBool: string) => {
      if (toggleBool == "true") {
        setQuickAddOpen(true)
      } else if (toggleBool == "false") {
        setQuickAddOpen(false)
      }
    }, [])

    const handleClick = useCallback(() => {
      trackProductClick(activeProduct?.product || item, firstAvailableVariant, pagePosition, list)
      handleTrackingClick && handleTrackingClick()
      if (isBrowser) {
        const currentPosition = document.documentElement.scrollTop
        const clickedTime = new Date().getTime()
        storage.set(
          keys?.collectionScrollPosition,
          JSON.stringify({
            collection: collectionHandle,
            position: currentPosition,
            clickedTime,
          })
        )
      }
    }, [
      item,
      trackProductClick,
      activeProduct?.product,
      firstAvailableVariant,
      pagePosition,
      list,
      handleTrackingClick,
      isBrowser,
      storage,
      keys?.collectionScrollPosition,
      collectionHandle,
    ])

    // intentionally only run once at first render
    useEffect(() => {
      if (window.location.pathname.includes("collections") || window.location.pathname.includes("search")) {
        if (pagePosition <= 12 && !isTracked) {
          trackProductImpression(item, pagePosition, list)
          setIsTracked(true)
        } else {
          setIsSensorActive(true)
        }
      } else {
        setIsSensorActive(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const visibleChange = (visible: boolean) => {
      if (pagePosition && visible && !isTracked) {
        trackProductImpression(item, pagePosition, list)
        setIsTracked(true)
      }

      if (!visible) {
        setQuickAdd("false")
      }
    }

    // Toggle wishlist add or remove
    const onWishlistToggle = useCallback(
      (event: React.BaseSyntheticEvent) => {
        event.preventDefault()

        const addItem: WishlistProduct = activeProduct ? { ...activeProduct?.product } : { ...item }

        if (firstAvailableVariant?.sku) addItem.selectedSku = firstAvailableVariant.sku
        if (firstAvailableVariant?.title) addItem.selectedTitle = firstAvailableVariant.title

        if (!inWishlist) {
          addToWishlist(addItem)
        } else {
          if (activeProduct && storefrontId) {
            deleteFromWishlist(storefrontId)
            setWishlistState(false)
          }
        }
      },
      [
        activeProduct,
        item,
        firstAvailableVariant?.sku,
        firstAvailableVariant?.title,
        inWishlist,
        addToWishlist,
        deleteFromWishlist,
        storefrontId,
      ]
    )

    const ref = useOutsideClick(() => setQuickAdd("false"))

    const paginationId = useMemo(() => `product-${item.handle}-pagination`, [item.handle])

    return (
      <GridItem colSpan={1} {...props} ref={itemRef}>
        <InView skip={!isSensorActive} threshold={0.5} onChange={visibleChange}>
          <div ref={ref}>
            <Box
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              pos="relative"
              mb={2.5}
              sx={{
                ".swiper-slide": { bg: "transparent" },
                ".swiper-pagination-bullets": {
                  pos: "absolute",
                  bottom: "5",
                  left: "0",
                  w: "full",
                  zIndex: "4",
                  pb: "0",
                },
                ".swiper-pagination-bullet": {
                  d: "inline-block",
                  w: "1.5",
                  h: "1.5",
                  borderRadius: "full",
                  bg: "grey.cloud",
                  opacity: "0.5",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                },
                ".swiper-pagination-bullet.swiper-pagination-bullet-active": {
                  bg: "grey.white",
                  opacity: "1",
                },
              }}
            >
              {/* Workaround to prevent link hover to trigger un-necessary re-rerenders, crashing local dev */}
              <div
                onMouseEnter={e => {
                  e.stopPropagation()
                  setIsHovered(true)
                }}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Link
                  to={activeProduct?.url || ""}
                  title={activeProduct?.title || item?.title}
                  role={!isTouch ? "group" : undefined}
                  onClick={handleClick}
                  display="block"
                  w="full"
                >
                  <AspectRatio bg="grey.cloud" ratio={aspectRatio} w="full">
                    <>
                      {isBrowser && !isSmall && !isHomepage && primaryImage && images && disableSwipe !== true ? (
                        <Suspense fallback={<Image {...primaryImage} alt={primaryImage.alt || item.title} loading={"eager"} />}>
                          <Swiper loop slidesPerView={1} pagination={{ el: `#${paginationId}` }}>
                            {images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <Box w="full">
                                  <Image {...image} alt={image.alt || item.title} w="full" lazy="true" loading="lazy" />
                                </Box>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Suspense>
                      ) : (
                        <>
                          {primaryImage && <Image {...primaryImage} alt={primaryImage?.alt || item.title} loading="eager" />}
                          {secondaryImage && isBrowser && !isHomepage && isHovered && (
                            <Image
                              {...secondaryImage}
                              alt={secondaryImage?.alt || item.title}
                              transition="all 0.2s ease"
                              opacity={0}
                              _groupHover={{ opacity: 1 }}
                              loading="lazy"
                            />
                          )}
                        </>
                      )}
                    </>
                  </AspectRatio>

                  {isBrowser && activeProduct && activeProduct.type !== giftCardType && (
                    <Suspense fallback={<></>}>
                      <ProductQuickAdd
                        productCardLanguage={productCardLanguage}
                        isLoading={loading}
                        quickAddOpen={quickAddOpen}
                        quickAddProduct={activeProduct}
                        quickAddSizes={productSizes}
                        setQuickAdd={setQuickAdd}
                        handleAddToCart={handleAddToCart}
                      />
                    </Suspense>
                  )}

                  {isBrowser && !showAddToCart && isCollectionPage && (
                    <Suspense fallback={<></>}>
                      <ProductWishlistButton inWishlist={inWishlist} isLoading={loading} onWishlistToggle={onWishlistToggle} />
                    </Suspense>
                  )}
                </Link>
              </div>
              {!isSmall ? <Box id={paginationId} /> : null}

              {isBrowser && (
                <Suspense fallback={<></>}>
                  <ProductContentBadges
                    pos="absolute"
                    bottom={0}
                    insetX={[1.5, 2.5]}
                    limit={3}
                    tags={activeProduct?.product?.tags ? activeProduct.product.tags : []}
                    variant={firstAvailableVariant}
                  />
                </Suspense>
              )}
            </Box>
            {/* Workaround to prevent link hover to trigger un-necessary re-rerenders, crashing local dev */}
            <div
              onMouseEnter={e => {
                e.stopPropagation()
                setIsHovered(true)
              }}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Link
                to={activeProduct?.url || ""}
                role={!isTouch ? "group" : undefined}
                title={activeProduct?.product?.title || ""}
                onClick={handleClick}
                display="block"
                w="full"
              >
                <Stack
                  direction={{ base: mobileLayout === "single" ? "row" : "column", md: "row" }}
                  alignItems="flex-start"
                  justifyContent="space-between"
                  letterSpacing="ample"
                  spacing={{ base: 1, md: 4 }}
                >
                  <Box flexGrow={1}>
                    <Text size="xs" mb={{ md: 1 }}>
                      {activeProduct?.product?.title || ""}
                    </Text>
                  </Box>
                  {isBrowser && activeProduct && activeProduct.type !== giftCardType && (
                    <Suspense fallback={<></>}>
                      <Box flexShrink={0}>
                        <ProductFormPrice
                          variant={firstAvailableVariant}
                          direction="row-reverse"
                          alignItems="flex-end"
                          spacing={2}
                          switchSalePrice
                          dividerSpacing={1}
                        />
                      </Box>
                    </Suspense>
                  )}
                </Stack>
              </Link>
            </div>
            {isBrowser && (
              <Suspense fallback={<></>}>
                <Discount tags={activeProduct?.product?.tags || []} fontSize={12} letterSpacing="generous" />
              </Suspense>
            )}

            {isBrowser && activeProduct && activeProduct.type !== giftCardType && (
              <Suspense fallback={<></>}>
                <ProductCardSwatches
                  activeProduct={activeProduct}
                  allSwatches={allSwatches}
                  item={item}
                  setAllSwatches={setAllSwatches}
                  setProductSizes={setProductSizes}
                  setActiveProduct={handleActiveProduct}
                  mt={0.75}
                />
              </Suspense>
            )}

            {isBrowser && showAddToCart && (
              <Suspense fallback={<></>}>
                <ProductAddToCart
                  inWishlist={inWishlist}
                  isLoading={loading}
                  productCardLanguage={productCardLanguage}
                  onWishlistToggle={onWishlistToggle}
                  onAddToCart={onAddToCartClick}
                />
              </Suspense>
            )}
          </div>
        </InView>
      </GridItem>
    )
  }
)
